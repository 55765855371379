<template>
  <div>
    <CRow>
      <CCol md="12" v-if="vendorUsersGridShow">
        <CCard>
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Firma Kullanıcıları</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input
                  type="text"
                  v-model="searchFilterInput"
                  autocomplete="off"
                  class="form-control mr-3"
                  style="max-width: 250px"
                  placeholder="Arama..."
                  v-on:keyup="onFilterTextBoxChanged()"
                />
                <RoleProvider slug="VENDOR_USER_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton
                      color="light"
                      variant="outline"
                      size="sm"
                      v-if="canItPass"
                      @click="
                        cleanVendorUserModalAddUpdateForm(),
                          (vendorUserModalAddUpdateOptions.process = 'post'),
                          (vendorUserModalAddUpdateOptions.title =
                            'Firma Kullanıcısı Ekle'),
                          (vendorUserModalAddUpdate = true)
                      "
                      class="py-2 px-3 font-weight-bold"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="mr-2"
                      />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :enableBrowserTooltips="true"
              :statusBar="statusBar"
              @grid-ready="onGridReady"
              @rowDoubleClicked="onRowDoubleClicked"
            ></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- :closeOnBackdrop="false" -->
    <CModal
      :title="vendorUserModalAddUpdateOptions.title"
      :closeOnBackdrop="false"
      :show.sync="vendorUserModalAddUpdate"
      class="vendorUserModalAddUpdateForm modalNoBorder"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="6">
                <CSelect
                  label="Durum"
                  :value.sync="vendorUserModalAddUpdateForm.status"
                  :options="vendorUserModalAddUpdateFormStatusOptions"
                />
              </CCol>
              <CCol md="12" lg="6">
                <CSelect
                  label="Firma"
                  :value.sync="vendorUserModalAddUpdateForm.vendorSlug"
                  :options="vendorUserModalAddUpdateFormVendorSlugOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CInput
                  label="Ad"
                  v-model="vendorUserModalAddUpdateForm.name"
                />
              </CCol>
              <CCol md="12" lg="6">
                <CInput
                  label="Soyad"
                  v-model="vendorUserModalAddUpdateForm.lastname"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol
                :col="
                  vendorUserModalAddUpdateOptions.process === 'post' ? 6 : 12
                "
              >
                <CInput
                  label="E-Posta"
                  v-model="vendorUserModalAddUpdateForm.email"
                />
              </CCol>
              <CCol
                md="12"
                lg="6"
                v-if="vendorUserModalAddUpdateOptions.process === 'post'"
              >
                <CInput
                  label="Şifre"
                  type="password"
                  autocomplete="new-password"
                  v-model="vendorUserModalAddUpdateForm.password"
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <RoleProvider slug="VENDOR_USER_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="
                vendorUserModalAddUpdateOptions.process === 'put' && canItPass
              "
              @click="vendorDelete(vendorUserModalAddUpdateForm._id)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>

        <RoleProvider slug="VENDOR_USER_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="warning"
              v-if="
                vendorUserModalAddUpdateOptions.process === 'put' && canItPass
              "
              @click="vendorUserModalPasswordChange = true"
              >Şifre Değiştir</CButton
            >
          </div>
        </RoleProvider>

        <RoleProvider slug="VENDOR_USER_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="
                canItPass && vendorUserModalAddUpdateOptions.process == 'put'
              "
              color="success"
              :disabled="vendorUserModalAddUpdateBtnDisable"
              @click="vendorUserAddUpdate()"
              >Düzenle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_USER_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="
                vendorUserModalAddUpdateOptions.process == 'post' && canItPass
              "
              color="success"
              :disabled="vendorUserModalAddUpdateBtnDisable"
              @click="vendorUserAddUpdate()"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>

    <!-- :closeOnBackdrop="false" -->
    <CModal
      title="Şifre Değiştir"
      :closeOnBackdrop="false"
      :show.sync="vendorUserModalPasswordChange"
    >
      <CRow>
        <CCol md="12">
          <CInput
            type="password"
            label="Yeni Şifre"
            autocomplete="new-password"
            v-model="vendorUserModalPasswordChangeForm.password"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          color="success"
          :disabled="vendorUserModalPasswordChangeBtnDisable"
          @click="vendorUserChangePassword()"
          >Değiştir</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";

var _ = require("lodash");
axios.defaults.withCredentials = true;

Vue.use(VueSweetalert2);

export default {
  name: "VendorUsers",
  created() {
    this.getVendorList();
    this.searchVendorUser();
  },
  components: {
    AgGridVue,
    RoleProvider,
  },
  data() {
    return {
      // AG GRID
      searchFilterInput: "",
      vendorUsersGridShow: false,
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            return params?.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params?.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },
        {
          field: "name",
          headerName: "Ad",
        },
        {
          field: "lastname",
          headerName: "Soyad",
        },
        {
          field: "email",
          headerName: "E-Posta",
        },
        {
          field: "vendorDetail.brand",
          headerName: "Firma",
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },

      // FİRMA ARACI ARAMA
      searchVendorUserFormCollapsed: false,
      searchVendorUserFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchVendorUserFormVendorSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorUserForm: {
        status: "",
        vendorSlug: "",
        name: "",
        lastname: "",
        email: "",
      },

      // FİRMA KULLANICISI EKLEME GÜNCELLEME MODAL
      vendorUserModalAddUpdate: false,
      vendorUserModalAddUpdateBtnDisable: false,
      vendorUserModalAddUpdateOptions: {
        process: "",
        title: "",
        shake: {
          name: false,
          lastname: false,
          email: false,
          password: false,
        },
      },
      vendorUserModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      vendorUserModalAddUpdateFormVendorSlugOptions: [],
      vendorUserModalAddUpdateFormErrors: {
        name: "",
        lastname: "",
        email: "",
        password: "",
      },
      vendorUserModalAddUpdateForm: {
        _id: "",
        status: false,
        vendorSlug: "",
        name: "",
        lastname: "",
        email: "",
        password: "",
      },

      // FİRMA KULLANICISI ŞİFRE DEĞİŞTİRME MODAL
      vendorUserModalPasswordChange: false,
      vendorUserModalPasswordChangeBtnDisable: false,
      vendorUserModalPasswordChangeOptions: {
        shake: {
          newPassword: false,
          newPasswordAgain: false,
        },
      },
      vendorUserModalPasswordChangeFormErrors: {
        validNewPassword: "",
        validNewPasswordAgain: "",
      },
      vendorUserModalPasswordChangeForm: {
        password: "",
      },
    };
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    getVendorList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor")
        .then((response) => {
          if (response.data.vendorList.length > 0) {
            response.data.vendorList.map(function (result) {
              _this.searchVendorUserFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });

              _this.vendorUserModalAddUpdateFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });
            });
          }
        })
        .catch((e) => {
        });
    },

    searchVendorUser() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor/users", {
          params: this.searchVendorUserForm,
        })
        .then((response) => {
          this.$Progress.finish();
          this.rowData = _.reverse(response.data.vendorUserList);
          this.searchVendorUserFormCollapsed = false;
          this.vendorUsersGridShow = true;
        });
    },
    vendorDelete(_id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu firma kullanıcısını silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/vendor/users/" + _id,
                { withCredentials: true }
              );
              this.$Progress.finish();
              this.vendorUserModalAddUpdate = false; // Modal'ı kapattık
              this.$store.dispatch({
                type: "successSwal",
                text: "Firma kullanıcısı başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    cleanVendorUserModalAddUpdateForm() {
      this.vendorUserModalAddUpdateForm._id = "";
      this.vendorUserModalAddUpdateForm.status =
        this.vendorUserModalAddUpdateFormStatusOptions[0]?.value;
      this.vendorUserModalAddUpdateForm.vendorSlug =
        this.vendorUserModalAddUpdateFormVendorSlugOptions[0]?.value;
      this.vendorUserModalAddUpdateForm.name = "";
      this.vendorUserModalAddUpdateForm.lastname = "";
      this.vendorUserModalAddUpdateForm.email = "";
      this.vendorUserModalAddUpdateForm.password = "";
    },

    vendorUserAddUpdate() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorUserModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      let params = {
        _id: this.vendorUserModalAddUpdateForm._id,
        status: this.vendorUserModalAddUpdateForm.status,
        vendorSlug: this.vendorUserModalAddUpdateForm.vendorSlug,
        name: this.vendorUserModalAddUpdateForm.name,
        lastname: this.vendorUserModalAddUpdateForm.lastname,
        email: this.vendorUserModalAddUpdateForm.email,
      };

      if (this.vendorUserModalAddUpdateOptions.process === "post")
        params.password = this.vendorUserModalAddUpdateForm.password;

      axios[this.vendorUserModalAddUpdateOptions.process](
        process.env.VUE_APP_API_URL + "admin/vendor/users",
        params
      ).then((response) => {
        this.$Progress.finish(); // Progress true ise gizliyoruz
        this.vendorUserModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

        if (response.data.result === "success") {
          this.$store.dispatch({
            type: "successSwal",
            text: "Firma kullanıcısı başarılı bir şekilde işlendi",
            refresh: true,
          });
        }
      });
    },

    vendorUserChangePassword() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorUserModalPasswordChangeBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/users",
          {
            _id: this.vendorUserModalAddUpdateForm._id,
            password: this.vendorUserModalPasswordChangeForm.password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorUserModalPasswordChangeBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            this.$store.dispatch({
              type: "successSwal",
              text: "Firma kullanıcısı başarılı bir şekilde şifresi güncellendi",
              refresh: true,
            });
          }
        });
    },

    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanVendorUserModalAddUpdateForm();
      _this.vendorUserModalAddUpdateOptions.process = "put";
      _this.vendorUserModalAddUpdateOptions.title = "Firma Kullanıcı Güncelle";

      _this.vendorUserModalAddUpdateForm._id = params.node.data._id;
      _this.vendorUserModalAddUpdateForm.status = params.node.data.status;
      _this.vendorUserModalAddUpdateForm.vendorSlug =
        params.node.data.vendorSlug;
      _this.vendorUserModalAddUpdateForm.name = params.node.data.name;
      _this.vendorUserModalAddUpdateForm.lastname = params.node.data.lastname;
      _this.vendorUserModalAddUpdateForm.email = params.node.data.email;

      _this.vendorUserModalAddUpdate = true;
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer !important;
}
</style>
